<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="deposits"
                          columnsPrefix="deposit.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :tbody-tr-class="rowClass"
                          title="deposit.title.deposits"
                >
                    <template #cell(number)="row">
                        <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
                           href="#">{{ row.item.number }}</a>
                    </template>

                    <template #cell(net_amount)="row">
                        {{ row.item.net_amount | priceFormat }} &euro;
                    </template>

                    <template #cell(vat_amount)="row">
                        {{ row.item.vat_amount | priceFormat }} &euro;
                    </template>

                    <template #cell(difference)="row">
                        {{ row.item.difference | priceFormat }} &euro;
                    </template>

                    <template #cell(status)="row">
                        {{ $t('deposit.status.' + row.item.status) }}
                    </template>

                    <template #cell(vat_rate)="row">
                        {{ row.item.vat_amount }}%
                    </template>

                    <template #cell(total_amount)="row">
                        {{ row.item.total_amount | priceFormat }} &euro;
                    </template>

                    <template #cell(actions)="row">
                        <b-button
                            @click="openRefund(row.item)"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Deposits',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Deposits', ['getDepositsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Deposits/fetchDeposits', 'getDepositsTable');
        },
        openRefund(deposit) {
            this.$root.$children[0].openModal('deposit-refund-modal', {
                    id: deposit.id,
                    status: deposit.status,
                }, this.refreshTable,
                {width: '800px'}
            )
        },
        rowClass(item, type) {
            if (item) {
                if (item.status == 'refunded') return 'table-success'
                if (item.status == 'partial_refund') return 'table-warning'
                if (item.status == 'withheld') return 'table-danger'
            }
        },
    },
}
</script>